<template>
  <div class="statementBox">
      <el-row  :gutter="20" >
          <el-col :span="6">
            <div class="section-wrapper">
                <div class="box plt">
                  <p class="cb">全部设备（已激活）</p>
                  <p>{{list.activateDevice || 0}}</p>
                </div>
                <div class="box plt">
                  <p>软件版</p>
                  <p>{{list.activateSwb || 0}}</p>
              </div>
              <div class="box plt">
                <p>硬件版</p>
                <p>{{list.activateHwb || 0}}</p>
                
              </div>
            </div>
          </el-col>
           <el-col :span="6">
            <div class="section-wrapper">
                <div class="box plt">
                  <p class="cb">全部设备（在线）</p>
                  <p>{{list.onlineDevice || 0}}</p>
                </div>
                <div class="box plt">
                  <p>软件版</p>
                  <p>{{list.onlineSwb || 0}}</p>
              </div>
              <div class="box plt">
                <p>硬件版</p>
                <p>{{list.onlineHwb || 0}}</p>
              </div>
            </div>
          </el-col>
           <el-col :span="6">
            <div class="section-wrapper">
                <div class="box plt">
                  <p class="cb">全部设备（离线）</p>
                  <p>{{list.offlineDevice || 0}}</p>
                </div>
                <div class="box plt">
                  <p>软件版</p>
                  <p>{{list.offlineSwb || 0}}</p>
              </div>
              <div class="box plt">
                <p>硬件版</p>
                <p>{{list.offlineHwb || 0}}</p>
              </div>
            </div>
          </el-col>
           <el-col :span="6">
            <div class="section-wrapper">
                <div class="box plt">
                  <p class="cb">全部设备（离线率）</p>
                  <p>{{list.deviceOfflineRate || 0}}%</p>
                </div>
                <div class="box plt">
                  <p>软件版</p>
                  <p>{{list.swbOfflineRate || 0}}%</p>
              </div>
              <div class="box plt">
                <p>硬件版</p>
                <p>{{list.hwbOfflineRate || 0}}%</p>
              </div>
            </div>
          </el-col>
         
      </el-row>
      <!--  -->
      <el-row :gutter="20" >
          <el-col :span="6">
              <div class="section-wrapper">
                <div class="plt">
                  <p>新增电池列表</p>
                  <p>{{list.addBattery || 0 }}</p>
                </div>
               
              </div>
          </el-col>
             <el-col :span="6">
              <div class="section-wrapper">
                <div class="plt">
                  <p>在线</p>
                  <p>{{list.onlineAddBattery || 0}}</p>
                </div>
              </div>
          </el-col>
             <el-col :span="6">
              <div class="section-wrapper">
                <div class="plt">
                  <p>离线</p>
                  <p>{{list.offlineAddBattery || 0}}</p>
                </div>
              </div>
          </el-col>
             <el-col :span="6">
              <div class="section-wrapper">
                <div class="plt">
                  <p>离线率</p>
                  <p>{{list.addBatteryOfflineRate || 0}}%</p>
                </div>
              </div>
          </el-col>
      </el-row>
        <!--  -->
      <el-row :gutter="20" >
          <el-col :span="6">
              <div class="section-wrapper">
                <div class="plt">
                  <p>电池出库列表</p>
                  <p>{{list.odBattery || 0}}</p>
                </div>
              </div>
          </el-col>
             <el-col :span="6">
              <div class="section-wrapper">
                <div class="plt">
                  <p>在线</p>
                  <p>{{list.onlineOdBattery || 0}}</p>
                </div>
              </div>
          </el-col>
             <el-col :span="6">
              <div class="section-wrapper">
                <div class="plt">
                  <p>离线</p>
                  <p>{{list.offlineOdBattery || 0}}</p>
                </div>
              </div>
          </el-col>
             <el-col :span="6">
              <div class="section-wrapper">
                <div class="plt">
                  <p>离线率</p>
                  <p>{{list.odBatteryOfflineRate || 0}}%</p>
                </div>
              </div>
          </el-col>
      </el-row>
        <!--  -->
      <el-row :gutter="20">
          <el-col :span="6">
              <div class="section-wrapper">
                <div class="plt">
                  <p>电池列表</p>
                  <p>{{list.battery || 0 }}</p>
                </div>
              </div>
          </el-col>
             <el-col :span="6">
              <div class="section-wrapper">
                <div class="plt">
                  <p>在线</p>
                  <p>{{list.onlineBattery || 0}}</p>
                </div>
              </div>
          </el-col>
             <el-col :span="6">
              <div class="section-wrapper">
                <div class="plt">
                  <p>离线</p>
                  <p>{{list.offlineBattery || 0}}</p>
                </div>
              </div>
          </el-col>
             <el-col :span="6">
              <div class="section-wrapper">
                <div class="plt">
                  <p>离线率</p>
                  <p>{{list.batteryOfflineRate || 0}}%</p>
                </div>
              </div>
          </el-col>
      </el-row>
        <!--  -->
      <el-row :gutter="20" >
          <el-col :span="6">
              <div class="section-wrapper">
                <div class="plt">
                  <p>售后电池列表</p>
                  <p>{{list.asBattery || 0}}</p>
                </div>
               
              </div>
          </el-col>
             <el-col :span="6">
              <div class="section-wrapper">
                <div class="plt">
                  <p>在线</p>
                  <p>{{list.onlineAsBattery || 0}}</p>
                </div>
               
              </div>
          </el-col>
             <el-col :span="6">
              <div class="section-wrapper">
                <div class="plt">
                  <p>离线</p>
                  <p>{{list.offlineAsBattery || 0}}</p>
                </div>
               
              </div>
          </el-col>
             <el-col :span="6">
              <div class="section-wrapper">
                <div class="plt">
                  <p>离线率</p>
                  <p>{{list.asBatteryOfflineRate || 0 }}%</p>
                </div>
               
              </div>
          </el-col>
      </el-row>
  </div>
</template>

<script>
import {getStatus} from "@/api/dataStatement"
export default {
  data() {
    return {
      list:[]
    }
  },
  mounted(){
    this.getStatus();
  },
  methods: {  
    async getStatus(){
      try{
        const params = {};
        const res = await getStatus(params);
        this.list = res.mdata.stateStatistics;
      }catch(e){
        console.log(e,'e');
      }

    }
  },
  components: {

  }
}
</script>

<style scoped lang="less">
.statementBox{
    .section-wrapper{
        p{
          margin-bottom: 10px ;
        }
    }
}
 
    
  
</style>
